<template>
  <div>
  
    <box-head
      :titleText="title"
      :showDropdown="false"
      :descriptionText="description"
    />

    <div class="box-content">
      <ul>
        <li>
          {{contacts.name}}
        </li>
        <li>
          <el-link class="custom-link" v-bind:href="'mailto:'+contacts.email">
            {{contacts.email}}
          </el-link>
        </li>
        <li v-if="contacts.displayPhone">
          <el-link class="custom-link" v-bind:href="'tel:'+contacts.phone">
            {{contacts.phone | phone}}
          </el-link>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
  import BoxHead from '../components/BoxHead.vue';
  import Api from '../services/api.js';
  export default {
    components: {
      BoxHead
    },
    data() {
      return {
        title: this.$i18n.t('errorPage.title'),
        description: this.$i18n.t('errorPage.description'),
        contacts: {
          name: "",
          phone: "",
          displayPhone: false,
          email: ""
        }
      }
    },
    mounted() {
      Api.getContacts().then((response) => {
        this.contacts = response.data;
      });
      if(this.$route.params.errCode) {
        this.title = this.$i18n.t(`errors.${this.$route.params.errCode}.title`);
        this.description = this.$i18n.t(`errors.${this.$route.params.errCode}.description`);
      }
    }
  }
</script>

<style scoped lang="scss">
  ul {
    display: block;
    padding:15px 0 0;
    margin:0;
    list-style:none;
    li {
      margin-bottom: 16px;
    }
  }
</style>